import React from "react"
//import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "gatsby"

class Header extends React.Component {

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-lg navbar-light  bg-white">
                    <div className="container-fluid">

                        <button className="navbar-toggler order-2 order-md-1" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbar-left navbar-right" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse order-3 order-md-2" id="navbar-left">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/">Trang Chủ</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/category/tin-tuc">Thông tin Sức Khoẻ</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <Link className="nav-link" to="/category/ban-doc-chia-se" title="chia sẻ về bệnh ung thư khí quản">
                                        Bạn đọc chia sẽ</Link>
                                </li>
                            </ul>
                        </div>
                        {this.props.tagName}
                        <div className="collapse navbar-collapse order-4 order-md-4" id="navbar-right">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/category/dinh-duong-ung-thu">Dinh Dưỡng Ung thư</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/category/phong-benh-ung-thu">Phòng Bệnh Ung thư</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/category/dinh-duong">Kiến Thức</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        )
    }
}

export default Header;
