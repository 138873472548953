import React from 'react';
import { Link } from "gatsby"

class Siderbar extends React.Component {

    render() {
        return (
            <>
                <aside className="sidebar sidebar-sticky">
                    <div className="card mb-4">
                    <div className="card-body">
                        <h4 className="card-title">Bài Đang Quan tâm</h4>
                        <Link to="/cach-tro-chuyen-voi-benh-nhi-ung-thu.html" className="d-inline-block">
                            <h4 className="h6">Cách trò chuyện với bệnh nhi ung thư</h4>
                        </Link>
                        <Link to="/nhung-sai-lam-pho-bien-ve-ung-thu-vu.html" className="d-inline-block">
                            <h4 className="h6">Những sai lầm phổ biến về ung thư vú</h4>
                        </Link>
                        <Link to="/nguoi-dan-ong-doi-mat-voi-ung-thu-phoi.html" className="d-inline-block">
                            <h4 className="h6">Người đàn ông đối mặt với ung thư phổi</h4>
                        </Link>
                    </div>
                    </div>
                </aside>
            </>
        )
    }
}

export default Siderbar;