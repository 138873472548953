import React, { Fragment } from "react"
import { Link } from "gatsby"

import 'bootstrap/dist/css/bootstrap.min.css';
import Header from "./Header";
import Footer from "./Footer";
import Siderbar from "./Siderbar";
import { Container, Row, Col } from 'react-bootstrap';
import "./Layout.css";
import logo from './logo_ungthukhiquan.png';
//import "./style.css";
class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <h1
          className="navbar-brand mx-auto order-1 order-md-3"
        >

          <Link className="navbar-brand nava_logo" to="/">
            <img className="nav_logo_img" src={logo} alt="Kiến thức bệnh Ung thư khí quản" />
          </Link>
        </h1>
      )
    } else {
      header = (
        <span
          className="navbar-brand mx-auto order-1 order-md-3"
        >
          <Link
            to={`/`}
          >
            <Link className="navbar-brand nava_logo" to="/">
              <img className="nav_logo_img" src={logo} alt="Thông tin kiến thức bệnh Ung thư khí quản" />
            </Link>
          </Link>
        </span>
      )
    }
    return (
      <Fragment>
        <Header tagName={header} />
        {/* <header>{header}</header> */}
        <Container fluid={true} className="bg-gray">
          <Row>
            <Col md={{ span: 9 }} className="list-post-home">
              <main>{children}</main>
            </Col>
            <div className="col-md-3 ml-auto">
              <Siderbar />
            </div>
          </Row>

        </Container>
        <Footer />
      </Fragment>
    )
  }
}

export default Layout
