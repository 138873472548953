import React from 'react';
import { Link } from "gatsby"
import logo from './logo_ungthukhiquan.png';
class Footer extends React.Component {

    render() {
        return (
            <div className="f-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-xs-12 w_linkbox">
                            <div className="widget-header">Về Chúng Tôi</div>
                            <ul className="w-list">
                                <li className="w-footer__linkbox-item">
                                    Địa chỉ: <b>210 Hồng bàng, Phường 11, Quận 5, Thành phố Hồ Chí Minh 700000</b>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    Email: <b>lienhe@ungthukhiquan.com</b>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    Hotline: <b>028 3855 4279</b>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 w_linkbox">
                            <div className="widget-header">Sức Khoẻ Ung Thư</div>
                            <ul className="w-list">
                                <li className="w-footer__linkbox-item">
                                    <Link to="/" className="w-footer__linkbox-link">
                                        Ung thư Gan
                                    </Link>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <Link to="/" className="w-footer__linkbox-link">
                                        Ung thư Khí quản
                                    </Link>
                                </li>
                                <li className="w-footer__linkbox-item">
                                <Link to="/" className="w-footer__linkbox-link">
                                        Ung thư Máu
                                    </Link>
                                </li>
                                <li className="w-footer__linkbox-item">
                                <Link to="/" className="w-footer__linkbox-link">
                                        Ung thư Đại tràng
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                        <div className="col-md-4 col-xs-12 w_linkbox">
                            <div className="widget-header">Chuyên Mục Khác</div>
                            <ul className="w-list">
                                <li className="w-footer__linkbox-item">
                                    <Link to="#" className="w-footer__linkbox-link">
                                        Hỏi đáp Bác Sĩ
                        </Link>
                                </li>
                                <li className="w-footer__linkbox-item">
                                    <Link to="/" className="w-footer__linkbox-link">
                                        Chia sẽ bạn đọc
                        </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer-green">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-9 col-xs-12">
                                <div className="row">
                                    <div className="col-md-4 col-xs-12 logo-f">
                                        <img className="f_logo" src={logo} alt="ung thư khí quản" />
                                    </div>
                                    <div className="col-md-8 col-xs-12 seo-text-f">
                                        <b>ungthukhiquan.com</b> - Chuyên trang blog tổng hợp chia sẽ thông tin về bệnh ung thư khí quản. 
                                        <p><small>Các bài viết trên site này chỉ có tính chất tham khảo, không thay thế cho việc chẩn đoán hoặc điều trị y khoa.</small></p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-xs-12">
                                <p className="f-fs">Follow Us</p>
                                <div className="social-network social-circle">
                                    <Link to="https://twitter.com/" className="icoTwitter" title="Twitter">
                                        {/* <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" className="svg-inline--fa fa-twitter fa-w-10 fa-3x"><path fill="currentColor" d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" className=""></path></svg> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" /></svg>
                                    </Link>
                                    <Link to="https://www.youtube.com/" className="icoLinkedin" title="Youtube">
                                        <svg height="20px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" className="svg-inline--fa fa-youtube fa-w-18 fa-3x"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z" className=""></path></svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;